import React from "react";
import logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
// Fontawesome

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <div className="footer-logo mt-5">
              <img className="img-fluid" src={logo} alt="logo" />
              <p className="mt-4 unique-text">
                Welcome to Virtual Studio, your partner for high-quality,
                consistent visual content. We specialize in creating impactful
                images, videos, graphics, and custom edits tailored for brands,
                manufacturers, and e-commerce businesses
              </p>
            </div>
          </div>

          <div className="col-md-3 mt-5 footer-header">
            <h1 className="mb-4 unique-text">Services</h1>

            <ul className="footer-links unique-text">
              <li>
                <Link className="footer-link" to="#">
                  AI Product video
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  AI Product Photography
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Video Editing
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Photo Editing
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Graphic Design
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Motion Graphic Design
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Scripting and Story Telling
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="#">
                  Consultation
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3 mt-5 footer-header">
            <h1 className="mb-4 unique-text">Contact Us</h1>

            <div className="footer-icon">
              <FontAwesomeIcon
                className="unique-text icon-color"
                icon={faInstagram}
              />

              <span>
                <Link className="link" to="https://Info@virtualstudio.com">
                  Virtual Studio
                </Link>
              </span>
            </div>

            <div className="footer-icon">
              <FontAwesomeIcon
                className="unique-text icon-color"
                icon={faFacebook}
              />

              <span>
                <Link className="link" to="https://Info@virtualstudio.com">
                  Virtual Studio
                </Link>
              </span>
            </div>

            <div className="footer-icon">
              <FontAwesomeIcon
                className="unique-text icon-color"
                icon={faEnvelope}
              />

              <span>
                <Link className="link" to="mailto:Info@virtualstudio.com">
                  Info@virtualstudio.com
                </Link>
              </span>
            </div>

            <div className="footer-icon">
              <FontAwesomeIcon
                className="unique-text icon-color"
                icon={faEnvelope}
              />

              <span>
                <Link className="link" to="mailto:Contact@virtualstudio.com">
                  Contact@virtualstudio.com
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="footer-down d-flex flex-column flex-md-row align-items-center justify-content-between ">
          {/* Left-Side: Privacy Policy and Terms */}
          <div className="footer-down-text d-flex flex-column flex-md-row align-items-center gap-3">
            <h1 className="mb-0">Privacy Policy</h1>
            <h1 className="mb-0">Terms & Conditions</h1>
          </div>

          {/* Centered: Copyright Text */}
          <div className="footer-down-text text-center order-md-0 order-1 my-3 my-md-0">
            <h1>
              Copyright © {currentYear}. Virtual Studio All rights reserved
            </h1>
          </div>

          {/* Right-Side: Icons */}
          <div className="footer-down-icon d-flex gap-3">
            <div className="footer-icon">
              <FontAwesomeIcon
                className="text-dark icon-color"
                icon={faInstagram}
              />
            </div>
            <div className="footer-icon">
              <FontAwesomeIcon
                className="text-dark icon-color"
                icon={faFacebook}
              />
            </div>
            <div className="footer-icon">
              <FontAwesomeIcon
                className="text-dark icon-color"
                icon={faEnvelope}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
