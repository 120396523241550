import React from "react";
import placeholder from "../../../assets/icons/placeholder.png";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
// Swiper

// Reviews
const Reviews = () => {
  const reviews = [
    {
      name: "Donna Stroupe",
      role: "customer",
      review:
        "I'm absolutely in love with Borcelle's services. I've been a customer for over 6 months now, and they are just the best.",
      rating: 5,
      avatar: "https://via.placeholder.com/50",
    },
    {
      name: "John Doe",
      role: "customer",
      review:
        "Great services and fantastic team. Highly recommended for anyone looking for quality and reliability.",
      rating: 5,
      avatar: "https://via.placeholder.com/50",
    },
    {
      name: "John Doe",
      role: "customer",
      review:
        "Great services and fantastic team. Highly recommended for anyone looking for quality and reliability.",
      rating: 5,
      avatar: "https://via.placeholder.com/50",
    },
    {
      name: "John Doe",
      role: "customer",
      review:
        "Great services and fantastic team. Highly recommended for anyone looking for quality and reliability.",
      rating: 5,
      avatar: "https://via.placeholder.com/50",
    },
    // Add more reviews as needed
  ];

  const ReviewCard = ({ review }) => {
    return (
      <div className="review-card p-3 border rounded">
        <div className="review-header d-flex align-items-center mb-2">
          <img
            src={placeholder}
            alt={review.name}
            className="avatar rounded-circle me-2"
            width="50"
            height="50"
          />
          <div>
            <h5 className="mb-0">{review.name}</h5>
            <small>{review.role}</small>
          </div>
        </div>
        <p className="review-text">{review.review}</p>
        <div className="review-rating">
          {"★".repeat(review.rating)}
          {"☆".repeat(5 - review.rating)}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-center mt-5 major-text">Customer Reviews</h2>
      <div className="container-fluid my-4 review-carousel mb-5">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={30}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 }, // Show 3 cards per row for larger screens
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <ReviewCard review={review} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* Reviews */}
    </div>
  );
};

export default Reviews;
