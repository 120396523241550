import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import logo from "../../assets/icons/logo.png";
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
// Fontawesome

// const moon = document.getElementById('faMoon');

// moon.addEventListener('click', function () {
//   // Toggle the 'dark-mode' class on the body element
//   document.body.classList.toggle('dark-mode');
// });

const Navbar = () => {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const location = useLocation(); // Detect route changes
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle("dark-mode", !isDarkMode);
  };

  useEffect(() => {
    const navbarCollapse = document.getElementById("navbarSupportedContent");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }
  }, [location]);


  return (
    <div className="navbar-header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top mb-5">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Brand Logo */}
          <Link className="navbar-brand logo" to="/">
            <h4>Virtual Studio</h4>
          </Link>

          {/* Hamburger and Theme Toggle */}
          <div className="d-flex align-items-center order-lg-3">
            {/* Theme Toggle Icon */}
            <FontAwesomeIcon
              id="themeToggleIcon"
              className="toggle-icon me-3"
              icon={isDarkMode ? faMoon : faSun}
              onClick={handleToggle}
              style={{
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            />

            {/* Hamburger */}
            <button
              className="navbar-toggler ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto text-center">
              <li className="nav-item">
                <Link
                  id="home"
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/works">
                  Recent Works
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/blogs">
                  Blogs
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/ai-product-video">
                      AI Product Video
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to="/ai-product-photography"
                    >
                      AI Product Photography
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Video Editing
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Photo Editing
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Graphic Design
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Motion Graphic Design
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Scripting and Story Telling
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="#">
                      Consultation
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {/* <form className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
