import React, { useState } from "react";

const Subscription = () => {
  // Subscription
  const [isMonthly, setIsMonthly] = useState(true);

  const togglePricing = () => {
    setIsMonthly(!isMonthly);
  };

  const plans = [
    {
      name: "BASIC",
      priceMonthly: "$10/month",
      priceQuarterly: "$27/quarter",
      button: "SUBSCRIBE",
    },
    {
      name: "STANDARD",
      priceMonthly: "$20/month",
      priceQuarterly: "$54/quarter",
      button: "SUBSCRIBE",
      popular: true,
    },
    {
      name: "CUSTOM",
      priceMonthly: "Contact Us",
      priceQuarterly: "Contact Us",
      button: "CUSTOMIZE",
    },
  ];
  // Subscription

  return (
    <div>
      {/* Subscrition */}
      <h1 id="getStarted" className="text-center major-text mt-5">
        Subscription Plan
      </h1>
      <div className="container py-5 unique-text">
        <div className="text-center mb-4">
          <div className="btn-group">
            <button
              className={`btn ${isMonthly ? "btn-light" : "btn-outline-light"}`}
              onClick={togglePricing}
            >
              Monthly
            </button>
            <button
              className={`btn ${
                !isMonthly ? "btn-light" : "btn-outline-light"
              }`}
              onClick={togglePricing}
            >
              Quarterly
            </button>
          </div>
        </div>

        <div className="row justify-content-center">
          {plans.map((plan, index) => (
            <div key={index} className="col-md-4 col-sm-6 mb-4">
              <div className="card bg-dark unique-text text-center border-light d-flex flex-column">
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">
                    {plan.name}
                    {plan.popular && (
                      <span className="badge bg-warning text-dark ms-2">
                        Popular
                      </span>
                    )}
                  </h5>
                  <p className="card-text">
                    {isMonthly ? plan.priceMonthly : plan.priceQuarterly}
                  </p>
                  <div className="mt-auto">
                    <button className="btn btn-outline-light  btn-plan">
                      {plan.button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Subscrition */}
    </div>
  );
};

export default Subscription;
