import React from "react";
import image_1 from "../../assets/images/aboutUs_1.png";
import image_2 from "../../assets/images/aboutUs_2.png";
// import image_3 from "../../assets/images/about_4.png";
// import image_4 from "../../assets/images/about_5.png";

const about = () => {
  return (
    <div>
      <div className="container">
        {/*  */}
        <div className="row about-us">
          <div className="contact-img  col-md-6">
            <img
              src={image_1}
              className="img-fluid animate__animated animate__fadeInLeft "
              alt=""
            />
          </div>

          <div className="contact-img-text col-md-6 ">
            <div className=" about-text animate__animated animate__fadeInDown ">
              <h1 className="unique-text">About Us</h1>
              <p></p>
            </div>
            <p className="animate__animated animate__slideInRight">
              Welcome to Virtual Studio, your partner for high-quality,
              consistent visual content. We specialize in creating impactful
              images, videos, graphics, and custom edits tailored for brands,
              manufacturers, and e-commerce businesses.
            </p>
          </div>
        </div>

        <div className="row about-us ">
          <div className="contact-img-text col-md-6 animate__animated animate__slideInLeft">
            <p className="about-text-2">
              Our flexible, subscription-based plans cater to small and
              medium-sized businesses from all parts of the world., providing
              high-quality visuals that keep your brand fresh, relevant, and
              engaging—all without the hassle of managing content in-house. Let
              us bring your vision to life, month after month.
            </p>
          </div>

          <div className="contact-img  col-md-6">
            <img
              src={image_2}
              className="img-fluid animate__animated animate__fadeInUp "
              alt=""
            />
          </div>
        </div>
        {/*  */}
      </div>
      <div className="break"></div>
    </div>
  );
};

export default about;
