import React from "react";

const AIProductPhotography = () => {
  return (
    <div>
       <div className="container">
                <div className="contact">
                    <div className="col md-6">
                        <h1>AI Product Photography</h1>
                    </div>
                </div>
            </div>

      <div className="break"></div>
    </div>
  );
};

export default AIProductPhotography;
