import React from "react";
import image_1 from "../../assets/images/blog_1.png";
import image_2 from "../../assets/images/blog_2.png";
import image_3 from "../../assets/images/blog_3.png";
import image_4 from "../../assets/images/blog_4.png";
import image_5 from "../../assets/images/blog_5.png";
// import image_6 from "../../assets/images/tree.png";
// Animate.css
import "animate.css";
// Animate.css

const Works = () => {
  const titles = [
    {
      id: 1,
      title: "Sunny Room",
      fade: "animate__animated animate__fadeInLeft",
    },
    {
      id: 2,
      title: "Sunny Day",
      fade: "animate__animated animate__fadeInDown",
    },
    {
      id: 3,
      title: "Sunny Tree",
      fade: "animate__animated animate__fadeInUp",
    },
    {
      id: 4,
      title: "Man in desert",
      fade: "animate__animated animate__slideInUp",
    },
    {
      id: 5,
      title: "Man with packs",
      fade: "animate__animated animate__slideInDown",
    },
    {
      id: 6,
      title: "Man with packs",
      fade: "animate__animated animate__slideInRight",
    },
  ];

  return (
    <div>
      <div className="container">
        <h1 className="mb-5 mt-5 major-text">Our Works</h1>
        <div className="row works animate__animated animate__fadeInLeft">
          {[image_1, image_2, image_3, image_4, image_5, image_1].map(
            (image, index) => (
              <div
                className={`col-md-4 hover-container ${titles[index].fade}`}
                key={index}
              >
                <div className="image-wrapper">
                  <img
                    src={image}
                    className="img-fluid"
                    alt={`Work ${index + 1}`}
                  />
                  <div className="hover-overlay">
                    <h5>{titles[index].title}</h5>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="break"></div>
    </div>
  );
};

export default Works;
