import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// Banner Images
import image_1 from "../../assets/images/bg_1.png";
import image_2 from "../../assets/images/bg_2.png";
import image_3 from "../../assets/images/bg_3.png";
import image_4 from "../../assets/images/bg_4.png";
import bannerLogo from "../../assets/logo/lOGO gif.gif";
// Banner Images

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// Fontawesome

// Animate.css
import "animate.css";
// Animate.css

// import timeless from "../../assets/videos/smple.mp4";

// Sections
import Reviews from "./sections/reviews";
import Blogs from "./sections/blogs";
import ImageSlider from "./sections/slider";
import Subscription from "./sections/subscriptions";
// Sections

// const getInTouch = document.getElementById("getInTouch");
// getInTouch.addEventListener("click", function () {
//   window.scrollTo({
//     bottom: 0,
//     behavior: "smooth",
//   });
// });

const Index = () => {
  // Gif preloader
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setAnimate(true);
    }, 3000); // Adjust time as needed to match your GIF duration

    return () => clearTimeout(timer);
  }, []);
  // Gif preloader

  const handleScroll = () => {
    document
      .getElementById("getStarted")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="container creative-header">
        {loading ? (
          <div className="preloader">
            <img
              src={bannerLogo}
              className={`preloader-img ${animate && "animate"}`}
              alt="Loading..."
              style={{ transform: animate ? "scale(1)" : "scale(0.5)" }}
            />
          </div>
        ) : (
          <div className="content">
            <div className="row">
              <div className="col-md-5">
                <div className="creative-title">
                  <h1 className="mb-3 unique-text">
                    Where creative process happens
                  </h1>
                  <p className="mt-4">
                    Your creativity, our inspiration Whatever your story, set if
                    free.
                  </p>

                  <button
                    onClick={handleScroll}
                    id="getInTouch"
                    type="button"
                    className="mr-5 mb-5"
                  >
                    Get in touch{" "}
                    <FontAwesomeIcon
                      className="unique-text icon-color text-center"
                      icon={faChevronCircleRight}
                    />{" "}
                  </button>
                </div>
              </div>

              <div className="col-md-7">
                <div className="row banner-img">
                  <div className="col-md-12">
                    <img
                      src={image_1}
                      className="img-fluid mb-3 animate__animated animate__fadeInDown animate-delay"
                      alt=""
                    />
                  </div>

                  <div className="col-md-4">
                    <img
                      src={image_3}
                      className="img-fluid animate__animated animate__fadeInLeft animate-delay"
                      alt=""
                    />
                  </div>

                  <div className="col-md-4">
                    <img
                      src={image_2}
                      className="img-fluid animate__animated animate__fadeInUp animate-delay"
                      alt=""
                    />
                  </div>

                  <div className="col-md-4">
                    <img
                      src={image_4}
                      className="img-fluid animate__animated animate__fadeInRight animate-delay"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="break"></div>

      <ImageSlider />

      <div className="break"></div>

      <Reviews />

      <div className="break"></div>

      <Blogs />

      {/* <div className="break"></div> */}
      {/* Video */}
      {/* <div className="container-fluid">
        <video
          className=""
          src={timeless}
          controls
          type="video/mp4"
          width="640"
          height="360"
        ></video>
      </div> */}
      {/* Video */}

      <div className="break"></div>

      <Subscription />

      <div className="break"></div>
    </div>
  );
};

export default Index;
