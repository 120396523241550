import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slider_1 from "../../../assets/images/about_1.png";
import slider_2 from "../../../assets/images/about_3.png";
import slider_3 from "../../../assets/images/about_4.png";
import slider_4 from "../../../assets/images/about_5.png";
import slider_5 from "../../../assets/images/aboutUs_2.png";
// Slider

const ImageSlider = () => {
  // Custom Next Arrow
  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow next" onClick={onClick}>
        &rarr;
      </div>
    );
  };

  // Custom Prev Arrow
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow prev" onClick={onClick}>
        &larr;
      </div>
    );
  };

  // Slider
  const mainSliderRef = useRef(null); // Reference to the main slider
  const slides = [
    { id: 1, image: slider_1 },
    { id: 2, image: slider_2 },
    { id: 3, image: slider_3 },
    { id: 4, image: slider_4 },
    { id: 5, image: slider_5 },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const handleThumbnailClick = (index) => {
    // Navigate the main slider to the clicked thumbnail's index
    mainSliderRef.current.slickGoTo(index);
  };
  // Slider

  // Slider
  // Slider

  return (
    <div>
      {/* SLider */}
      <div className="">
        <h1 className="text-center mt-5 major-text">Our Works</h1>
        <div className="slider-container">
          {/* Main Image Slider */}
          <Slider {...settings} ref={mainSliderRef}>
            {slides.map((slide) => (
              <div className="main-slide" key={slide.id}>
                <img
                  src={slide.image}
                  alt={`Slide ${slide.id}`}
                  className="main-image"
                />
              </div>
            ))}
          </Slider>

          {/* Thumbnail Slider */}
          <div className="thumbnail-slider">
            {slides.map((slide, index) => (
              <div
                className="thumbnail"
                key={slide.id}
                onClick={() => handleThumbnailClick(index)} // Add click handler
              >
                <img
                  src={slide.image}
                  alt={`Thumbnail ${slide.id}`}
                  className="thumbnail-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* SLider */}
    </div>
  );
};

export default ImageSlider;
