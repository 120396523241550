import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/static/navbar";
import Home from './components/pages/index';
import About from './components/pages/about';
import Works from './components/pages/works';
import Blogs from './components/pages/blogs';
import Contact from './components/pages/contact';
import AIProductVideo from './components/pages/ai-product-video';
import AIProductPhotography from './components/pages/ai-product-photography';
// import Subscription from './components/pages/slider';
import ScrollToTopButton from "./components/static/scrollTop";
import Footer from './components/static/footer';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./style.css"

const App = () => {
  return (
    <Router>
      <Navbar />
      {/* <Header /> */}
      <div className="mt-4">
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/about" element={<About />} /> 
           <Route path="/works" element={<Works />} /> 
           <Route path="/blogs" element={<Blogs />} /> 
           <Route path="/contact" element={<Contact />} /> 
           <Route path="/ai-product-video" element={<AIProductVideo />} /> 
           <Route path="/ai-product-photography" element={<AIProductPhotography />} /> 
           {/* <Route path="/slider" element={<Subscription />} />  */}
              {/* <Route path="/men" element={<Men />} />                */}
        </Routes>
      </div>
      <Footer /> 
      <ScrollToTopButton /> 
    </Router>
  );
};

export default App;
