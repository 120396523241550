// Blogs
import blog_1 from "../../../assets/images/aboutUs_1.png";
import blog_2 from "../../../assets/images/aboutUs_1.png";
import blog_3 from "../../../assets/images/aboutUs_1.png";
import blog_4 from "../../../assets/images/aboutUs_1.png";
import { Link } from "react-router-dom";
// Blogs

const Blogs = () => {
  // Blogs
  const blogs = [
    {
      id: 1,
      image: blog_1, // Replace with your image URL
      title: "How Monthly Content Packages Can Transform Your Brand Strategy",
    },
    {
      id: 2,
      image: blog_2, // Replace with your image URL
      title: "NOW WHAT?",
    },
    {
      id: 3,
      image: blog_3, // Replace with your image URL
      title: "From Overwhelmed to Organized",
    },
    {
      id: 4,
      image: blog_4, // Replace with your image URL
      title: "The Revolution of Artificial Intelligence",
    },
    {
      id: 5,
      image: blog_4, // Replace with your image URL
      title: "The Revolution of Artificial Intelligence",
    },
    {
      id: 6,
      image: blog_4, // Replace with your image URL
      title: "The Revolution of Artificial Intelligence",
    },
  ];

  const BlogCard = ({ blog }) => {
    return (
      <div className="blog-card">
        <Link to={`/blogs?token=${blog.id}`}>
          <img src={blog.image} alt={blog.title} className="blog-image" />
          <div className="overlay">
            <h5 className="overlay-title">{blog.title}</h5>
            {/* <h5 className="card-title">{blog.title}</h5> */}
          </div>
        </Link>
      </div>
    );
  };
  // Blogs

  return (
    <div>
      {/* Blogs */}
      <h1 className="text-center mt-5 major-text">Blogs</h1>
      <div className="blogs ">
        <div className="container blogs-container my-5">
          <div className="row g-4">
            {blogs.map((blog) => (
              <div className="col-md-4 mb-4" key={blog.id}>
                <BlogCard blog={blog} />
              </div>
            ))}
          </div>
        </div>
        <button className="blog-btn">See More</button>
      </div>
      {/* Blogs */}
    </div>
  );
};

export default Blogs;
