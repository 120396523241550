import React from "react";

const Contact = () =>{
    return(
        <div>
            <div className="container">
                <div className="contact">
                    <div className="col md-6">
                        <h1>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum minus omnis ipsam earum odio. Veniam mollitia ex deleniti reiciendis soluta quaerat, molestias, vitae quia, corporis alias rerum nisi quo reprehenderit!</h1>
                    </div>
                </div>
            </div>

            <div className="break"></div>
        </div>
    )
}


export default Contact;